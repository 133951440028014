import { useState } from 'react';
import { onVerifyCaptcha } from '../../util/api';

const useCaptcha = () => {
  const [captchaToken, setCaptchaToken] = useState(null); // To store the CAPTCHA token
  const [isVerified, setIsVerified] = useState(false); // To track whether CAPTCHA is verified
  const [errorMessage, setErrorMessage] = useState(''); // For error handling

  const verifyCaptcha = async (token) => {
    // Send the token to the backend for verification
    try {
      const response = await onVerifyCaptcha({ token })

      if (response.data.success) {
        setIsVerified(true);
        setErrorMessage('');
      } else {
        setIsVerified(false);
        setErrorMessage('reCAPTCHA verification failed. Please try again.');
      }
    } catch (error) {
      setIsVerified(false);
      setErrorMessage('There was an error verifying the reCAPTCHA. Please try again later.');
    }
  };

  const onCaptchaChange = (token) => {
    setCaptchaToken(token);
    if (token) {
      verifyCaptcha(token); // Verify the token when it changes
    }
  };

  return {
    captchaToken,
    isVerified,
    errorMessage,
    onCaptchaChange,
  };
};

export default useCaptcha;
